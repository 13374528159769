import {
  collection,
  CollectionReference,
  query,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  ObservableStatus,
  useFirestore,
  useFirestoreCollectionData,
} from "reactfire";
import { CoverLetterCollection } from "liz-cover-letter-editor/shared";
import { db } from "../utilities/firebase";

type UseCoverLetterCollection = {
  status: ObservableStatus<unknown>["status"];
  data: CoverLetterCollection[];
  createDocument: (data: CoverLetterCollection) => Promise<void>;
  updateDocument: (
    id: string,
    data: Partial<CoverLetterCollection>
  ) => Promise<void>;
  deleteDocument: (id: string) => Promise<void>;
};

const useCoverLetterCollection = (): UseCoverLetterCollection => {
  const firestore = useFirestore();
  const coverLettersCollection = collection(
    firestore,
    "cover-letters"
  ) as CollectionReference<CoverLetterCollection>;
  const coverLettersQuery = query(coverLettersCollection);
  const { status, data } = useFirestoreCollectionData(coverLettersQuery);

  const createDocument: UseCoverLetterCollection["createDocument"] = async (
    data
  ) => {
    if (!data.uid) {
      throw new Error('A "uid" is required when creating a document');
    }
    const ref = doc(db, "cover-letters", data.uid);
    await setDoc(ref, data);
  };

  const updateDocument: UseCoverLetterCollection["updateDocument"] = async (
    id,
    data
  ) => {
    const ref = doc(db, "cover-letters", id);
    await updateDoc(ref, data);
  };

  const deleteDocument: UseCoverLetterCollection["deleteDocument"] = async (
    id
  ) => {
    const ref = doc(db, "cover-letters", id);
    await deleteDoc(ref);
  };

  return {
    status,
    data: data || [],
    createDocument,
    updateDocument,
    deleteDocument,
  };
};

export { useCoverLetterCollection };
