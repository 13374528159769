import { Component, ReactNode } from "react";

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

/* eslint-disable require-jsdoc */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <main className="fixed h-full w-full flex flex-col gap-4 justify-center items-center">
          <h1>Oh no! There was an issue.</h1>
          <p>Reach out to the site&apos;s admin for assistance.</p>
        </main>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
