import { FC, ReactNode, useRef } from "react";
import { createPortal } from "react-dom";
import { concat } from "../utilities/strings";
import { Icon } from "./Icon";

type ModalProps = {
  title: string;
  open: boolean;
  children: ReactNode;
  close: () => void;
  wrapperClassName?: string;
  mountNode?: Element;
};

const Modal: FC<ModalProps> = ({
  title,
  open,
  children,
  wrapperClassName,
  mountNode: controlledMountNode,
  close,
}) => {
  const mountNode = controlledMountNode || document.body;
  const modalRef = useRef<HTMLElement>(null);

  return createPortal(
    <aside
      ref={modalRef}
      className={concat(
        wrapperClassName,
        "print:hidden",
        "fixed",
        "flex-col",
        "bg-white",
        "top-0",
        "w-full",
        "h-full",
        "py-4",
        "z-20",
        "transition-transform",
        !open && "translate-y-full"
      )}
    >
      <div className="lg:max-w-3xl lg:mx-auto">
        <h2 className="flex justify-between px-4 mb-4 text-3xl">
          {title}
          <button
            className="flex justify-end items-center w-12"
            onClick={close}
          >
            <Icon
              title="close icon"
              name="ri-close-circle-line"
              className="w-6 h-6"
            />
          </button>
        </h2>
        {children}
      </div>
    </aside>,
    mountNode
  );
};

export { Modal };
