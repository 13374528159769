import { FC } from "react";
import { Modal } from "../../../components/Modal";
import { Button } from "../../../components/SaveButton";

type DeleteModalProps = {
  name: string;
  open: boolean;
  close: () => void;
  remove: () => void;
};

const DeleteModal: FC<DeleteModalProps> = ({ name, open, close, remove }) => {
  return (
    <Modal title={`Delete ${name}`} open={open} close={close}>
      <div className="flex flex-col gap-2 px-4">
        <p>Are you sure you want to delete this cover letter?</p>
        <p>This action CAN NOT be undone.</p>
        <Button className="mt-8 bg-red-500 hover:bg-red-700" onClick={remove}>
          Delete Now
        </Button>
      </div>
    </Modal>
  );
};

export { DeleteModal };
