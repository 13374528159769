import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCUwDBexJwqZB0EWOrdEaODWcoZaI33QzM",
  authDomain: "lizcoverletter.firebaseapp.com",
  projectId: "lizcoverletter",
  storageBucket: "lizcoverletter.appspot.com",
  messagingSenderId: "886289824631",
  appId: "1:886289824631:web:b86810ed80f9d7cb2dff72",
  measurementId: "G-31NY7C0CNB",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
