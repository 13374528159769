import { ButtonHTMLAttributes, FC } from "react";
import { useUser } from "../hooks/useUser";
import { Icon } from "./Icon";

type ControlsProps = {
  saveButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  deleteButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  openHelp: () => void;
  showLoad: () => void;
  showSaveAs: () => void;
  showSave: () => void;
  showDelete: () => void;
};

const Controls: FC<ControlsProps> = ({
  saveButtonProps = {},
  deleteButtonProps = {},
  openHelp,
  showLoad,
  showSaveAs,
  showSave,
  showDelete,
}) => {
  const { signOut } = useUser();

  return (
    <div className="lg:fixed top-4 left-4 flex lg:flex-col justify-between gap-4 print:hidden mb-4">
      <button onClick={openHelp} className="flex gap-2 items-center">
        <Icon name="ri-question-line" className="w-8 h-8" />
        Help
      </button>
      <button onClick={showLoad} className="flex gap-2 items-center">
        <Icon name="ri-folder-open-line" className="w-8 h-8" />
        Open
      </button>
      <button onClick={showSaveAs} className="flex gap-2 items-center">
        <Icon name="ri-file-add-line" className="w-8 h-8" />
        Save As
      </button>
      <button
        {...saveButtonProps}
        onClick={showSave}
        className="flex gap-2 items-center"
      >
        <Icon name="ri-save-line" className="w-8 h-8" />
        Save
      </button>
      <button
        {...deleteButtonProps}
        onClick={showDelete}
        className="flex gap-2 items-center"
      >
        <Icon name="ri-delete-bin-2-line" className="w-8 h-8" />
        Delete
      </button>
      <button onClick={signOut} className="lg:mt-8 flex gap-2 items-center">
        <Icon name="ri-logout-box-line" className="w-8 h-8" />
        Sign Out
      </button>
    </div>
  );
};

export { Controls };
