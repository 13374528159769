import { FC, InputHTMLAttributes } from "react";
import { concat } from "../utilities/strings";

const Input: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  ...rest
}) => {
  return (
    <input
      {...rest}
      className={concat(className, "rounded border-2", "border-blue-400")}
    />
  );
};

export { Input };
