export const defaultCompany = "[Company Name]";

export const defaultStreet = "[Street]";

export const defaultCityStateZip = "[City], [State] [Zip]";

export const defaultIntro = "Dear [Who is this being addressed to],";

export const defaultBodyContent = `Add your cover letter body content here.`;

export const defaultCoverLetter = {
  uid: null,
  company: defaultCompany,
  companyStreet: defaultStreet,
  cityStateZip: defaultCityStateZip,
  intro: defaultIntro,
  bodyContent: defaultBodyContent,
};
