import { FormEvent, useEffect, useRef, useState } from "react";
import { useStorage, useStorageDownloadURL } from "reactfire";
import { ref } from "firebase/storage";
import { Editable } from "../../components/Editable";
import { Controls } from "../../components/Controls";
import { Modal } from "../../components/Modal";
import { defaultCoverLetter } from "../../defaults";
import { useCoverLetterCollection } from "../../hooks/useCoverLetterCollection";
import { useCoverLetter } from "../../hooks/useCoverLetter";
import { Button } from "../../components/SaveButton";
import { Input } from "../../components/Input";
import { DeleteModal } from "./modals/DeleteModal";

const Editor = (): JSX.Element => {
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showLoad, setShowLoad] = useState<boolean>(false);
  const [showSaveAs, setShowSaveAs] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const storage = useStorage();
  const signatureRef = ref(storage, "signature.jpg");
  const { data: signatureURL } = useStorageDownloadURL(signatureRef);
  const {
    data: coverLetters,
    createDocument,
    updateDocument,
    deleteDocument,
  } = useCoverLetterCollection();
  const { coverLetter, dispatch } = useCoverLetter();
  const { company, companyStreet, cityStateZip, intro, bodyContent } =
    coverLetter;
  const [scale, setScale] = useState<number>(1);

  const editorRef = useRef<HTMLDivElement>(null);

  const selectCoverLetter = (index: number): void => {
    const coverLetter = coverLetters[index];
    dispatch({ ...coverLetter });
    setShowLoad(() => false);
  };

  const handleSaveAs = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const uid = formData.get("cover-letter-uid")?.toString();
    if (!uid) {
      return;
    }
    if (uid.includes(" ")) {
      return;
    }
    await createDocument({
      ...coverLetter,
      uid,
    });
    dispatch({ uid });
    setShowSaveAs(() => false);
  };

  const handleSave = async (): Promise<void> => {
    if (!coverLetter.uid) {
      throw new Error('A "uid" is required when creating a document');
    }
    await updateDocument(coverLetter.uid, coverLetter);
    setShowSave(() => false);
  };

  const handleRemove = async (): Promise<void> => {
    if (!coverLetter.uid) {
      throw new Error('A "uid" is required when removing a document');
    }
    await deleteDocument(coverLetter.uid);
    dispatch({
      ...defaultCoverLetter,
    });
    setShowDelete(() => false);
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (window.innerWidth < 1024 && editorRef.current) {
        const scale = Math.min(
          (window.innerWidth - 100) / editorRef.current.clientWidth,
          1
        );
        setScale(scale);
      }
    });
    observer.observe(document.body);
    return () => observer.unobserve(document.body);
  }, []);

  return (
    <div className="font-body flex flex-col lg:flex-row bg-gray-300 p-4 w-screen print:bg-white print:p-0 print:w-[8.5in] print:h-[11in]">
      <Controls
        saveButtonProps={{
          disabled: !coverLetter.uid,
          className: "disabled:opacity-10 disabled:cursor-not-allowed	",
        }}
        deleteButtonProps={{
          disabled: !coverLetter.uid,
          className: "disabled:opacity-10 disabled:cursor-not-allowed	",
        }}
        openHelp={(): void => setShowHelp(() => true)}
        showLoad={(): void => setShowLoad(() => true)}
        showSaveAs={(): void => setShowSaveAs(() => true)}
        showSave={(): void => setShowSave(() => true)}
        showDelete={(): void => setShowDelete(() => true)}
      />
      <div
        ref={editorRef}
        className="relative bg-white m-auto max-w-[8.5in] w-[8.5in] h-[11in] origin-top-left	"
        style={{ transform: `scale(${scale})` }}
      >
        <div className="print:hidden">
          <div className="absolute bg-red-400 top-6 h-px w-full z-10"></div>
          <div className="absolute bg-red-400 bottom-6 h-px w-full z-10"></div>
          <div className="absolute bg-red-400 left-6 h-full w-px z-10"></div>
          <div className="absolute bg-red-400 right-6 h-full w-px z-10"></div>
        </div>
        <header className="flex justify-between items-center bg-gradient-to-br from-[#D7E6E7] to-[#D8F0F1] pt-6 px-6 pb-4">
          <h1 className="font-header tracking-[3px] text-4xl">
            Elizabeth McMullan
          </h1>
          <div>
            <p>Senior Assistant Director</p>
            <p>Tampa, FL 33772</p>
            <p>lizmcm413@gmail.com</p>
          </div>
        </header>
        <main className="px-6 pb-6">
          <section className="pt-3 pb-3">
            <Editable
              onBlur={(e): void =>
                dispatch({ company: e.currentTarget.innerHTML })
              }
            >
              {company}
            </Editable>
            <Editable
              onBlur={(e): void =>
                dispatch({ companyStreet: e.currentTarget.innerHTML })
              }
            >
              {companyStreet}
            </Editable>
            <Editable
              onBlur={(e): void =>
                dispatch({ cityStateZip: e.currentTarget.innerHTML })
              }
            >
              {cityStateZip}
            </Editable>
          </section>
          <section>
            <div className="pb-3">
              <Editable
                onBlur={(e): void =>
                  dispatch({ intro: e.currentTarget.innerHTML })
                }
              >
                {intro}
              </Editable>
            </div>
            <Editable
              dangerouslySetInnerHTML={{ __html: bodyContent }}
              onBlur={(e): void =>
                dispatch({ bodyContent: e.currentTarget.innerHTML })
              }
            />
          </section>
        </main>
        <footer className="px-6 pb-6 flex flex-col gap-4">
          <p className="pt-3">Thank you for your consideration,</p>
          <div>
            <img className="h-10 -translate-x-3" src={signatureURL} />
          </div>
          <p>Elizabeth McMullan</p>
        </footer>
      </div>
      <Modal
        title="Help"
        open={showHelp}
        close={(): void => setShowHelp(() => false)}
      >
        <div className="flex flex-col gap-2 px-4">
          <h3>Print Settings</h3>
          <ol className="list-decimal px-4">
            <li>Change &quot;Destination&quot; to &quot;Save as PDF&quot;</li>
            <li>Expand &quot;More Settings&quot;</li>
            <li>Change &quot;Margin&quot; to &quot;None&quot;</li>
            <li>Check &quot;Background Graphics&quot;</li>
          </ol>
          <img src="/print-settings.jpg" className="w-2/3 mx-auto" />
        </div>
      </Modal>
      <Modal
        title="Load Cover Letter"
        open={showLoad}
        close={(): void => setShowLoad(() => false)}
      >
        <div className="flex flex-col gap-2 px-4">
          {coverLetters.map(({ uid }, index) => (
            <Button key={uid} onClick={(): void => selectCoverLetter(index)}>
              {uid}
            </Button>
          ))}
        </div>
      </Modal>
      <Modal
        title="Save Cover Letter As"
        open={showSaveAs}
        close={(): void => setShowSaveAs(() => false)}
      >
        <form onSubmit={handleSaveAs} className="flex flex-col px-4">
          <label htmlFor="cover-letter-name" className="pb-2">
            Enter the name cover letter
            <span className="text-gray-600 ml-2">
              only &quot;-&quot; allowed, no spaces
            </span>
          </label>
          <Input id="cover-letter-name" name="cover-letter-uid" />
          <Button className="mt-8" type="submit">
            Save As
          </Button>
        </form>
      </Modal>
      <Modal
        title={`Save ${coverLetter.uid}`}
        open={showSave}
        close={(): void => setShowSave(() => false)}
      >
        <div className="flex flex-col gap-2 px-4">
          <p>Are you sure you want to save these changes?</p>
          <Button className="mt-8" onClick={handleSave}>
            Save Now
          </Button>
        </div>
      </Modal>
      <DeleteModal
        name={coverLetter.uid || ""}
        open={showDelete}
        close={(): void => setShowDelete(() => false)}
        remove={handleRemove}
      />
    </div>
  );
};

export { Editor };
