import { useCallback } from "react";
import { signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import { useUser as useFireUser } from "reactfire";
import { auth } from "../utilities/firebase";

type UseUser = {
  user: ReturnType<typeof useFireUser>;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
};

const useUser = (): UseUser => {
  const user = useFireUser();

  const signIn = useCallback<UseUser["signIn"]>(async () => {
    await signInWithRedirect(auth, new GoogleAuthProvider());
  }, []);

  const signOut = useCallback<UseUser["signOut"]>(async () => {
    await auth.signOut();
    location.reload();
  }, []);

  return { user, signIn, signOut };
};

export { useUser };
