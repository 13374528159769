import { useReducer } from "react";
import { CoverLetterCollection } from "liz-cover-letter-editor/shared";
import {
  defaultBodyContent,
  defaultCityStateZip,
  defaultCompany,
  defaultIntro,
  defaultStreet,
} from "../defaults";

const reducer = (
  state: CoverLetterCollection,
  newState: Partial<CoverLetterCollection>
): CoverLetterCollection => {
  return {
    ...state,
    ...newState,
  };
};

type UseCoverLetter = {
  coverLetter: CoverLetterCollection;
  dispatch: (arg: Partial<CoverLetterCollection>) => void;
};

const useCoverLetter = (): UseCoverLetter => {
  const [state, dispatch] = useReducer(reducer, {
    uid: null,
    company: defaultCompany,
    companyStreet: defaultStreet,
    cityStateZip: defaultCityStateZip,
    intro: defaultIntro,
    bodyContent: defaultBodyContent,
  });

  return { coverLetter: state, dispatch };
};

export { useCoverLetter };
