import { useSigninCheck } from "reactfire";
import { Editor } from "./pages/editor";
import { Login } from "./pages/login";

const App = (): JSX.Element => {
  const { status, data: signInCheckResult } = useSigninCheck();

  return signInCheckResult?.signedIn ? (
    <Editor />
  ) : (
    <Login loading={status === "loading"} />
  );
};

export default App;
