import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  StorageProvider,
} from "reactfire";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";
import { auth, db, firebaseConfig, storage } from "./utilities/firebase";
import { ErrorBoundary } from "./components/ErrorBoundary";

const root = createRoot(document.getElementById("root") as HTMLDivElement);
root.render(
  <StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={db}>
          <StorageProvider sdk={storage}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </StorageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
