import { ButtonHTMLAttributes, FC } from "react";
import { concat } from "../utilities/strings";

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={concat(
        className,
        "flex",
        "transition-colors",
        "bg-blue-600",
        "hover:bg-blue-800",
        "text-white",
        "py-2",
        "px-4",
        "rounded",
        "shadow-sm",
        "w-max"
      )}
    >
      {children}
    </button>
  );
};

export { Button };
