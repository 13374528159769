import { FC, useState } from "react";
import { Icon } from "../../components/Icon";
import { useUser } from "../../hooks/useUser";
import { concat } from "../../utilities/strings";

type LoginProps = {
  loading: boolean;
};

const Login: FC<LoginProps> = ({ loading }) => {
  const { signIn } = useUser();
  const [hasError, setHasError] = useState<boolean>(false);

  const containerStyles = concat(
    "flex",
    "items-center",
    "transition-shadow",
    "duration-300",
    "bg-[#4285f4]",
    "rounded-sm",
    "border",
    "border-[#4285f4]",
    "shadow-sm",
    "hover:shadow-[#4285f4]",
    "text-white",
    "fill-black"
  );

  const handleSignIn = async (): Promise<void> => {
    try {
      await signIn();
    } catch (error) {
      console.error(error);
      setHasError(() => true);
    }
  };

  return (
    <main className="flex flex-col justify-center items-center fixed w-full h-full">
      <h1 className="text-3xl pb-12">Cover Letter Builder</h1>
      {loading && (
        <div className={containerStyles}>
          <div className="bg-white p-4">
            <Icon
              name="ri-loader-line"
              className="animate-[spin_3s_linear_infinite] w-6 h-6"
            />
          </div>
          <p className="px-6">Loading Application</p>
        </div>
      )}
      {!loading && (
        <button className={containerStyles} onClick={handleSignIn}>
          <span className="bg-white p-4">
            <Icon
              href="custom.symbol.svg"
              name="google-icon"
              className="w-6 h-6"
            />
          </span>
          <p className="mx-auto px-6">Sign in w/ Google</p>
        </button>
      )}
      {hasError && <p className="pt-8">Oh no! Something went wrong.</p>}
    </main>
  );
};

export { Login };
